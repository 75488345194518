<template>
  <div id="app" class="app">
    <app-header></app-header>
    <app-viewport></app-viewport>
    <app-spanish-footer v-if="showSpanishFooter"></app-spanish-footer>
  </div>
</template>

<script>
import AppHeader from 'src/.maintenance/components/app-header.vue';
import AppViewport from 'src/.maintenance/components/app-viewport.vue';
import AppSpanishFooter from 'src/.maintenance/components/app-spanish-footer.vue';

export default {
  components: {
    AppHeader,
    AppViewport,
    AppSpanishFooter
  },
  metaInfo(){
    let title = this.$route.meta.title || 'Maintenance';

    return {
      title: (title !== 'mycasino.ch') ? title.charAt(0).toUpperCase() + title.slice(1) : title
    };
  },
  computed: {
    showSpanishFooter() {
      return ['pinatacasino.es', 'goldenbull.es', 'speedybet.es'].includes(this.$route.meta.title);
    }
  }
}
</script>

<style>
html {
  box-sizing: border-box;
  font-size: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  height: 100vh;
  margin: 0;
  overflow: hidden;

  position: relative;
}

a {
  color: var(--app-color-brand);
}
</style>

<style scoped>
.app {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
}

.app >>> .app-header {
  flex: 0 0 auto;

  position: relative;
  z-index: 100;
}

.app >>> .app-viewport {
  flex: 1;
}

.app >>> .app-content {
  margin: 3em 0;
  margin-bottom: 0;

  display: grid;
  grid-template-columns: minmax(1em, 1fr) minmax(auto, 80ch) minmax(1em, 1fr);
}

.app >>> .app-content__column {
  margin: 0 0 3em;

  display: grid;
  grid-column: 2;
  grid-template-columns: 100%;
  align-items: flex-start;
}

.app >>> aside.app-content__column {
  font-size: .8em;
}

.app >>> .app-content__column h1 {
  margin: 1rem 0;

  font-size: 2em;
  letter-spacing: -.025em;
}

.app >>> .app-content__column h2 {
  margin: 1rem 0 0;

  letter-spacing: -.025em;
}

.app >>> .app-content__column p {
  margin: 0;

  font-size: 1.25em;
}

@media screen and (min-width:480px) {
  .app >>> .app-content__column h1 {
    font-size: 2.5em;
  }
}
</style>