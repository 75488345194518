<template>
    <footer class="app-spanish-footer">
      <ul>
        <li v-for="(legalDetail, index) in getLegalDetailsList" :key="index">
          <strong>{{legalDetail.detail}}</strong>
          <p>{{legalDetail.text}}</p>
        </li>
      </ul>
      <div>
        <a href="https://www.paf.es/safer-gaming/age-restrictions"><img src="../../.resources/pngs/18.png"></a>
        <a href="https://www.ordenacionjuego.es/es/op-PafConsulting"><img src="../../.resources/pngs/juego_1.png"></a>
        <a href="https://www.juegoseguro.es/"><img src="../../.resources/pngs/juego_2.png"></a>
        <a href="https://www.paf.es/safer-gaming"><img src="../../.resources/pngs/juego_3.png"></a>
        <a href="https://www.ordenacionjuego.es/es/rgiaj"><img src="../../.resources/pngs/auto_1.png"></a>
        <a href="https://www.paf.es/profile/self-exclusion#self-exclusion"><img src="../../.resources/pngs/auto_2.png"></a>
        <a href="https://www.jugarbien.es/"><img src="../../.resources/pngs/jugar.png"></a>
      </div>
    </footer>
  </template>

<script>
export default {
  computed: {
    getLegalDetailsList() {
      const legalDetails = this.$t("legal-details.items");
      return Array.isArray(legalDetails) ? legalDetails : undefined;
    },
  },
};
</script>

<style scoped>
.app-spanish-footer {
  display: flex;
  flex-direction: column;
  background: var(--app-footer-background);
  padding: 10px 20px 20px 20px;
}

.app-spanish-footer ul {
  list-style-type: none;
  font-size: 0.8em;
  padding: 10px;
  color: var(--app-footer-text);
}

.app-spanish-footer strong, .app-spanish-footer p {
  display: inline;
}

.app-spanish-footer > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.app-spanish-footer > div >:not(:first-child) {
  margin-left: 20px;
}

.app-spanish-footer img {
  max-height: 35px;
  max-width: 120px;
  width: auto;
  height: auto;
}
</style>